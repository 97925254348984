<template>
  <div class="infor_box">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navin"></nav-m>
    <inforcen @pushCrubms="pushCrubms"></inforcen>
    <foot></foot>
   
  </div>
</template>
<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import inforcen from "@/components/information/infroCen.vue";
import foot from "@/components/publick/foot.vue";
export default {
  components: {
    blaners,
    navM,
    inforcen,
    foot,
    
  },
  data() {
    return {
      navin: [],
      nav1:[
         { title:this.$i18n.t('nav.home'), url: "/" },
        { title: this.$i18n.t('nav.info'), url: "/infor" }
      ],
      nav2:[
        { title:this.$i18n.t('nav.home'), url: "/" },
        { title: this.$i18n.t('nav.customerCa'), url: "/customerCa" }
      ],
      imgList: []
    };
  },
   mounted() {
    this.getimgList();
    if(this.$route.query.bool){
      this.navin=this.nav2
    }else{
      this.navin=this.nav1
    }
  },
  methods: {
    getimgList() {
      this.axios
        .post("api/ad/index?", this.qs.stringify({ meun: 7  , status_en: this.$root.statusEn}))
        .then(res => {
          this.imgList = res.data.data;
        });
    },
    pushCrubms(obj){
      this.navin = this.navin.slice(0, 2);
      this.navin.push(obj);
    }
  }
};
</script>

<style  scoped>
</style>