<template>
  <div class="container ku">
    <div class="card mb-3" style="width:100%;">
      <div class="row no-gutters">
        <div class="col-md-4 de">
          <!-- <img
            :src="'http://admin.croxborder.com/'+list.image"
            class="card-img img-fliud d-none d-sm-none d-md-block d-lg-block"
            alt="..."
          /> -->
        </div> 
        <div class="col-md-7">
          <div class="card-body yuu">
            <h5 class="card-title h3font">
              <router-link :to="{name:'softInfor',query:{id:list.id}}">{{list.title}}</router-link>
            </h5>
            <div class="line"></div>
            <p class="card-text h3font">{{list.ex_title}}</p>
            <p class="card-text ko h3font" v-html="list.content"></p>
            <div class="bott"></div>
 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    // this.getlist();
  },
  methods: {
    getlist() {
      this.axios
        .post(
          "api/ad/menu",
          this.qs.stringify({ meun: 10, status_en: this.$root.statusEn })
        )
        .then(res => {
          this.list = res.data.data.data[0];
          console.log(this.list, "232430000000000000000");
        });
    }
  }
};
</script>

<style  scoped>
.ku {
  cursor: pointer;
  margin-top: 30px;
}
.ku :hover {
  color: #65a8ff;
}

.row {
  display: flex;
  justify-content: space-between;
}
.card {
  border: none;
}
.card-title a {
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-decoration: none;
}
.card-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.bott {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.card-body :nth-child(4) {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.line {
  width: 110px;
  height: 5px;
  background-color: #d4d4d4;
  margin-bottom: 10px;
}
.bott {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.de {
  display: flex !important;
  align-items: center;
}

.de img {
  transition: all 0.6s;
}
.de img:hover {
  transform: scale(0.9);
}
@media screen and (max-width: 700px) {
  .bott {
    font-size: 12px;
    color: red;
    background-color: red;
  }
  .h3font a {
    font-size: 16px;
  }
  .h3font {
    font-size: 12px;
  }
  .h3font p {
    font-size: 12px;
  }
}
</style>