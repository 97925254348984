<template>
  <div class="resolve">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navm"></nav-m>
    <contentr :navList="navlist" :tcImgList="tcImgList" :showLi='showLi'></contentr>
    <foot></foot>
  </div>
</template>
<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import contentr from "@/components/resolve/content.vue";
import foot from "@/components/publick/foot.vue";

export default {
  data() {
    return {
      navm: [
        { title: this.$i18n.t('nav.home'), url: "/" },
        { title: this.$i18n.t('nav.resolveFan'), url: "/resolveFan" }
      ],
      navlist: [
        { title: this.$i18n.t('resolve.Outline'), url: "#i1" },
        { title: this.$i18n.t('resolve.Scheme'), url: "#i2" },
        { title: this.$i18n.t('resolve.characteristics'), url: "#i3" },
        { title: this.$i18n.t('resolve.User'), url: "#i4" }
      ],
      imgList: [
        { imgurl: require("@/assets/img/sol.png"), id: 0 },
        { imgurl: require("@/assets/img/sol.png"), id: 1 },
        { imgurl: require("@/assets/img/sol.png"), id: 2 }
      ],
      textId:'',
      context:'',
      TextList:[],
      tcImgList:[],
      showLi:true
    };
  },
  mounted() {
    this.getimgList();
     
    // this.$route.query.id,
    this.textId= this.$route.query.id;
 this.getTextList();
  },
  methods: {
    getimgList() {
      this.axios
        .post("api/ad/index?", this.qs.stringify({ meun: 4 , status_en: this.$root.statusEn }))
        .then(res => {
          this.imgList = res.data.data;
        });
    },
    getTextList(){

       var params = {
        limit: 3,
        id: this.textId,
        status_en: this.$root.statusEn
      };
      this.axios
        .post("api/ad/pt?", params)
        .then(res => {
          this.TextList = res.data.data;
          this.tcImgList=this.TextList.dtp
          this.context=this.TextList.dtp[0].content
          //面包屑导航设置
          this.navm = this.navm.slice(0, 2);
          this.navm.push({
            title: this.$i18n.locale == 'zh_CN' ? this.TextList.title : this.TextList.title_en,
            url: false
          });
          this.setTitle(this.$i18n.locale == 'zh_CN' ? this.TextList.title : this.TextList.title_en);
        });
    }
  },
  components: {
    blaners,
    navM,
    contentr,
    foot
  }
};
</script>

<style  scoped>
</style>