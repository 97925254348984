<template>
  <div class="resolve">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navm"></nav-m>
    <contentr :navList="navlist"></contentr>
    <foot></foot>
  </div>
</template>
<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import contentr from "@/components/resolve/content.vue";
import foot from "@/components/publick/foot.vue";

export default {
  data() {
    return {
      navm: [
        { title: this.$i18n.t('nav.home'), url: "/" },
       { title: this.$i18n.t('nav.resolveFan'), url: "/resolveFan" },
        { title: this.$i18n.t('nav.warehouse'), url: "/resolve" }
      ],
      navlist: [
        { title: this.$i18n.t('resolve.Outline'), url: "#i1" },
        { title: this.$i18n.t('resolve.Scheme'), url: "#i2" },
        { title: this.$i18n.t('resolve.characteristics'), url: "#i3" },
        { title: this.$i18n.t('resolve.characteristics'), url: "#i4" }
      ],
      imgList: [
        { imgurl: require("@/assets/img/bl.png"), id: 0 },
        { imgurl: require("@/assets/img/bl.png"), id: 1 },
        { imgurl: require("@/assets/img/bl.png"), id: 2 }
      ]
    };
  },
  components: {
    blaners,
    navM,
    contentr,
    foot
  },
  mounted() {
    this.getimgList();
  },
  methods: {
    getimgList() {
      this.axios
        .post("api/ad/index", this.qs.stringify({ meun: 4 }))
        .then(res => {
          this.imgList = res.data.data;
        });
    }
  }
};
</script>

<style  scoped>
</style>