<template>
  <div class="softP">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navm"></nav-m>
    <soft-top></soft-top>
      <soft-con></soft-con>
    <!-- <soft-cl1></soft-cl1>
    <soft-con1></soft-con1>
    <soft-cl></soft-cl>   -->
    
    <product></product>

    <foot></foot>
    <left/>
  </div>
</template>

<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import softTop from "@/components/softPro/spftTop.vue";
import softCon from "@/components/softPro/softCon.vue";
import softCon1 from "@/components/softPro/softCon1.vue";
import softCl from "@/components/softPro/softConl.vue";
import softCl1 from "@/components/softPro/softConl1.vue";
import foot from "@/components/publick/foot.vue";
import left from "@/components/publick/leftT.vue";
import product from "@/views/product.vue";

export default {
  components: {
    blaners,
    navM,
    softTop,
    softCon,
    softCon1,
    softCl,
    softCl1,
    foot,
    left,
    product
  },
  data() {
    return {
      imgList: [
        { imgurl: require("@/assets/img/soo.png"), id: 0 },
        { imgurl: require("@/assets/img/soo.png"), id: 1 },
        { imgurl: require("@/assets/img/soo.png"), id: 2 }
      ],
      navm: [
        { title: this.$i18n.t('nav.home'), url: "/" },
        { title:this.$i18n.t('nav.software'), url: "/softp" }
      ]
    };
  },
  mounted() {
    console.log("黄",this.$route)
    this.getimgList();
    this.setTitle(this.$i18n.t('nav.software'));
  },
  methods: {
    getimgList() {
      this.axios
        .post("api/ad/index?", this.qs.stringify({ meun: 3  , status_en: this.$root.statusEn}))
        .then(res => {
          this.imgList = res.data.data;
        });
        console.log("软件产品",this.imgList)
    }
  }
};
</script>

<style  scoped>
</style>