<template>
    <div class="informatio_box">
        <blaners :imgList="imgList"></blaners>
        <nav-m :navM="navin"></nav-m>
        <contentr></contentr>
        <foot></foot>
        <left/>
    </div>
</template>

<script>
    import blaners from "@/components/publick/blanerS.vue";
    import navM from "@/components/solutinMethod/navM.vue";
    import contentr from "@/components/information/conten.vue";
    import foot from "@/components/publick/foot.vue";
    import left from "@/components/publick/leftT.vue";

    export default {
        components: {
            blaners,
            navM,
            contentr,
            foot,
            left
        },
        data() {
            return {
                navin: [
                    {title: this.$i18n.t('nav.home'), url: "/"},
                    {title: this.$i18n.t('nav.info'), url: "/infor"},
                    {title: this.$i18n.t('nav.All'), url: "/infor"}
                ],
                imgList: [
                    {imgurl: require("@/assets/img/zx.png"), id: 0},
                    {imgurl: require("@/assets/img/zx.png"), id: 1},
                    {imgurl: require("@/assets/img/zx.png"), id: 2}
                ]
            };
        },
        mounted() {
            this.getimgList();
            this.setTitle(this.$i18n.t('nav.info'));
        },
        methods: {
            getimgList() {
                this.axios
                    .post("api/ad/index?", this.qs.stringify({meun: 7, status_en: this.$root.statusEn}))
                    .then(res => {
                        this.imgList = res.data.data;
                    });
            }
        }
    };
</script>

<style lang="stylus" scoped></style>
