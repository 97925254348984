<template>
  <div class="solutionBox">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navm"></nav-m>
    <allpro></allpro>
    <foot />
    <left/>
  </div>
</template>
<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import allpro from "@/components/solutinMethod/allPro.vue";
import foot from "@/components/publick/foot.vue";
import left from "@/components/publick/leftT.vue";
export default {
  data() {
    return {
      imgList: [
        { imgurl: require("@/assets/img/52.png"), id: 0 },
        { imgurl: require("@/assets/img/52.png"), id: 1 },
        { imgurl: require("@/assets/img/52.png"), id: 2 }
      ],

      navm: [
        { title: this.$i18n.t("nav.home"), url: "/" },
        { title: this.$i18n.t("nav.customerCa"), url: "/customerCa" }
      ],
      navl: [
        { title: "全部", url: "" },
        { title: "海外仓服务解决方案", url: "" },
        { title: "货代综合解决方案", url: "" },
        { title: "FBA转运解决方案", url: "" },
        { title: "跨境电商供应链解决方案", url: "" }
      ],
      cur_page: 1, //默认在第一页
      pageNum: 9, //默认每页显示1条数据
      totalCount: 124, //默认总条数为一条,
      filter: {
        limit: 9,
        page: 1,
        meun: 12,
        status_en: this.$root.statusEn
      }
    };
  },
  components: {
    blaners,
    navM,
    allpro,
    foot,
    left
  },
  mounted() {
    this.getimgList();
    this.setTitle(this.$i18n.t('nav.customerCa'));
  },

  methods: {
    handleCurrentChange(val) {
      this.cur_page = val;
      this.filter.page = val;
    },

    getimgList() {
      this.axios
        .post("api/ad/index?", this.qs.stringify({ meun: 5 , status_en: this.$root.statusEn}))
        .then(res => {
          this.imgList = res.data.data;
        });
    }
  }
};
</script>

<style scoped>
</style>