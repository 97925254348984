<template>
  <div class="container">
    <div class="top_little h3font" >{{detail.title}}</div>
    <div class="time">
      <div class="time_left">
        <span>{{$t('home.time')}}：</span>
        <span>{{detail.date}}</span>
      </div>
      <div class="time_right">
        <!-- <span>{{$t('home.source')}}：</span>
        <span>{{detail.from}}</span> -->
      </div>
    </div>
    <!-- <div class="m_img de">
      <img src="../../assets/img/200.png" class="img-fluid" alt="Responsive image" />
    </div>-->
    <p class="card-text" v-html="detail.content"></p>
  </div>
</template>
<script>
export default {
  props: ['pushCrubms'],
  data() {
    return {
      detail: [],
      id: ""
      // filter: {
      //   id: 1
      // }
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getdetail();
  },

  methods: {
    getdetail() {
      // var that=this
      var params = {
        id: this.$route.query.id,
        status_en: this.$root.statusEn
      };
      this.axios.post("api/ad/view", params).then(res => {
        this.detail = res.data.data;
        let crumbs = {
          title: this.$i18n.locale == 'zh_CN' ? this.detail.title : this.detail.title_en,
          url: false
        };
        this.$emit('pushCrubms',crumbs);
        this.setTitle(this.$i18n.locale == 'zh_CN' ? this.detail.title : this.detail.title_en);
      });
    }
  },
  watch: {
    $route() {
      this.getdetail();
    },
    deep: true
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}
.top_little {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c8c8c8;
}
.m_img {
  margin-top: 10px;
  margin-bottom: 20px;
}
.card-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  margin-bottom: 10px;
}
@media screen and (max-width: 600px){
  .h3font{
    font-size: 18px;
     font-weight: 800;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
  }
  .top_little{
    min-height: 50px;
  }
}
</style>
