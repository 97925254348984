<template>
    <div class="resolveBox" style="background:#f5f5f5;">
        <blaners :imgList="imgList"></blaners>
        <nav-m :navM="navm"></nav-m>
        <!-- <resolver></resolver> -->
        <div class="container bb">
            <div class="card gg" style="width: 18rem;" v-for="(item,index) of list" :key="index">
                <div class="top" :class="index%3==0? 'bhu':index%3==1? 'phu':index%3==2? 'koui':'koui'">
                    <div class="imgBox">
                        <img class="card-img-top img-fluid" :src="'http://admin.croxborder.com/'+item.image"
                             alt="Card image cap"/>
                    </div>


                    <div class="btTitle">
                        <h5 class="text-center tex h3font">{{item.title}}</h5>
                        <p class="text-center tep h3font">{{item.ex_title}}</p>

                    </div>


                </div>
                <div class="card-body bod">
                    <p class="card-text tt " v-html="item.content"></p>

                    <div class="botto ">
                        <router-link :to="{name:'resolve',query:{id:item.id}}">{{$t('home.openSolution')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <nav aria-label="Page navigation example">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="filter.page"
                    :page-size="filter.limit"
                    layout="total, prev, pager, next"
                    :total="totalCount"
                    style="text-align:center"
            ></el-pagination>
        </nav>
        <foot></foot>
        <left/>


    </div>
</template>

<script>
    import blaners from "@/components/publick/blanerS.vue";
    import navM from "@/components/solutinMethod/navM.vue";
    import resolver from "@/components/resolveFan/resolvueCon.vue";
    import foot from "@/components/publick/foot.vue";
    import left from "@/components/publick/leftT.vue";

    export default {
        components: {
            blaners,
            navM,
            resolver,
            foot,
            left
        },
        data() {
            return {
                filter: {
                    limit: 6,
                    page: 1,
                    meun: -1,
                    status_en: this.$root.statusEn
                },
                cur_page: 1,
                totalCount: 0,
                imgList: [
                    {imgurl: require("@/assets/img/solo.png"), id: 0},
                    {imgurl: require("@/assets/img/solo.png"), id: 1},
                    {imgurl: require("@/assets/img/solo.png"), id: 2}
                ],
                navm: [
                    {title: this.$i18n.t("nav.home"), url: "/"},
                    {title: this.$i18n.t("nav.resolveFan"), url: "/resolveFan"}
                ],
                list: []
            };
        },
        mounted() {
            this.getimgList();
            this.getlist();
            this.setTitle(this.$i18n.t('nav.resolveFan'));
        },
        methods: {
            handleCurrentChange(val) {
                this.cur_page = val;
                this.filter.page = val;
                console.log(this.filter.page, 'lolololol')
                this.getlist()
            },
            getimgList() {
                this.axios
                    .post("api/ad/index?", this.qs.stringify({meun: 4, status_en: this.$root.statusEn}))
                    .then(res => {
                        this.imgList = res.data.data;
                    });
            },
            getlist() {
                var params = {
                    limit: 6,
                    meun: 7,
                    page: this.filter.page,
                    status_en: this.$root.statusEn
                };
                this.axios
                    .post("api/ad/menu", params)
                    .then(res => {
                        this.list = res.data.data.data;
                        this.totalCount = res.data.data.total;
                        console.log(this.totalCount, 'lolo........>>>>>>')
                    });
            }
        }
    };
</script>


<style scoped>
    /* .card-body{
      height: 300px;
    } */
    .resolveBox {
        background: #ffff !important;

    }

    /deep/ .el-pager li.number {
        color: #65A8FF;
    }

    /deep/ .el-pager li.active {
        color: black;
    }

    .card-text /deep/ p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .card {
        margin-top: 40px;
        margin-bottom: 20px;
        box-shadow: 0px 0px 30px 5px #efefef;
    }

    .imgBox {
        width: 67px;
        height: 71px;
        /* margin-bottom: 10px; */
        /* margin-top: 50px; */
        /* background: red; */
        overflow: hidden;
    }

    .imgBox image {
        /* width: 60px;
        height: 60px; */
        /* height: 100%;
        width: 100%; */
    }

    .btTitle {
        /* background: red; */
        height: 2rem;
    }

    .bhu {
        background-color: #65A8FF
    }

    .phu {
        background-color: #F6921E
    }

    .koui {
        background-color: #FF7366;
    }

    .top {
        width: 100%;
        height: 217px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .tex {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .tep {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .tt {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;

        width: 100%;
    }

    .botto {
        width: 174px;
        height: 48px;
        border: 1px solid rgba(101, 168, 255, 1);
        text-align: center;
        line-height: 48px;
        margin: 0 auto;
        /* margin-top: 91px; */
    }

    .botto a {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        text-decoration: none;
    }

    .bod {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 280px;
    }

    .bod :hover {
        color: #65a8ff;
    }

    .bod > .tt /deep/ p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }

    .gg {
        transition: all 0.3s;
        cursor: pointer;
    }

    .gg:hover {
        transform: scale(0.95);
        color: #65a8ff;
    }

    /* .bb  /deep/  .gg {
      transition: all 0.6s;
      cursor: pointer;
    }
    .bb /deep/ .gg :hover {
      transform: scale(0.9);
    } */
    .resolveBox .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    @media screen and (max-width: 500px) {
        .bb {
            justify-content: center;
        }

        .resolveBox .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .h3font {
            font-size: 12px;
        }

        .bod {
            height: 220px;
        }

        .top {
            height: 160px;
        }

        .card-text /deep/ p {
            font-size: 12px;
        }

        .botto {
            width: 150px;
            height: 40px;
            line-height: 40px;
            margin-top: 50px;
        }

        .card {
            margin-top: 0;
        }

        .imgBox img {
            width: 40px;
            height: 45px;
        }
    }
</style>