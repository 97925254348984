<template>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-3 tab-item" v-for="(v, i) of navList" :key="i" @click="change(i)">
          <a :href="v.url" :class="i == num ? 'aa' : ''">{{ v.title }}</a>
        </div>
      </div>
    </div>

    <div class="container vb">
      <h5 id="i1" v-if="showLi">{{$t('resolve.Outline')}}</h5>
      <h5 id="i1" v-else>{{$t('resolve.SoftwareRemark')}}</h5>
      <div class="line_m"></div>
      <div class="card" style="width:100%;"></div>
    </div>

    <div class="container-fluid gg" v-if="tcImgList[0]">
      <div class="container">
        <img v-if="tcImgList[0].image"
          :src="'http://admin.croxborder.com/'+tcImgList[0].image"
          class="img-fluid"
          alt="Responsive image"
        />
        <div class="card-body" v-if="tcImgList[0]" v-html="tcImgList[0].content"></div>
      </div>
    </div>

    <div class="container-fluid ku" v-if="tcImgList[1]">
      <div class="container">
        <h5 id="i2" v-if="showLi">{{$t('resolve.Scheme')}}</h5>
        <h5 id="i2" v-else>{{$t('resolve.SoftwareContent')}}</h5>
        <div class="line_m"></div>
        <img v-if="tcImgList[1].image"
          :src="'http://admin.croxborder.com/'+tcImgList[1].image"
          class="img-fluid"
          alt="Responsive image"
        />
        <div class="card-body" v-if="tcImgList[1]" v-html="tcImgList[1].content"></div>
      </div>
    </div>

    <div class="container-fluid ku" v-if="tcImgList[2]">
      <div class="container">
        <h5 id="i3" v-if="showLi">{{$t('resolve.characteristics')}}</h5>
        <h5 id="i3" v-else>{{$t('resolve.SoftwareFeature')}}</h5>
        <div class="line_m"></div>

        <img v-if="tcImgList[2].image"
          :src="'http://admin.croxborder.com/'+tcImgList[2].image"
          class="img-fluid"
          alt="Responsive image"
        />
        <div class="card-body" v-if="tcImgList[2]" v-html="tcImgList[2].content"></div>
      </div>
    </div>

    <div class="container-fluid ku" v-if="tcImgList[3]">
      <div class="container">
        <h5 id="i4" v-if="showLi">{{$t('resolve.User')}}</h5>
        <h5 id="i4" v-else>{{$t('resolve.UserFeature')}}</h5>
        <div class="line_m"></div>
        <img v-if="tcImgList[3].image"
          :src="'http://admin.croxborder.com/'+tcImgList[3].image"
          class="img-fluid"
          alt="Responsive image"
        />
        <div class="card-body" v-if="tcImgList[3]" v-html="tcImgList[3].content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // props: ["navList","tcImgList"],
  props: {
    navList: {
      default: () => [],
      type: Array
    },
    tcImgList: {
      default: () => [],
      type: Array
    },

    showLi: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      num: ""
    };
  },
  mounted() {
    // console.log("详情页的数据", this.tcImgList);
    // console.log(this.showLi, "llloooooo");
    // console.log(this.navList)
  },
  methods: {
    change(i) {
      this.num = i;
    }
  },
  watch: {
    tcImgList() {
      // console.log(this.tcImgList[0].image,"oooooooooooo")
      // console.log(this.tcImgList,'fmssb')
    }
  }
};
</script>

<style scoped>
.tab-item {
  transition: all 0.3s;
}
.tab-item:hover {
  transition: all 0.3s;
  font-weight: 600;
}
.col-md-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-md-3 a {
  text-decoration: none;
  font-size: 14px;
  color: black;
}
.col-md-3 .aa {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 55px;
  background: url("../../assets/img/9.png") no-repeat center;
  background-size: 100% 100%;
  color: white;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.line_m {
  width: 98px;
  height: 3px;
  background: rgba(246, 146, 30, 1);
}
.card {
  border: none;
}
.list {
  margin-top: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.list li {
  margin-bottom: 5px;
  color: black;
}
.vb {
  margin-top: 40px;
}
.gg {
  background-color: #f7f7f7;
}
.ku {
  margin-top: 30px;
  margin-bottom: 60px;
}
</style>
