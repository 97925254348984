<template>
  <div class="about">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navm"></nav-m>
    <aboutus></aboutus>
    <aboutab></aboutab>
    <!--<Nav :num="sum"></Nav>-->
    <!--<div class="container-fluid ko">
      <Nav :fms="$t('home.certificate')" :fms2="jj"></Nav>
      <hezuo :num="sum" :list='list'></hezuo>
    </div>-->
    <Nav :fms="$t('home.aualification')" :fms2="tt"></Nav>
    <biao></biao>
    <foot></foot>
    <left/>
  </div>
</template>
<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import aboutus from "@/components/about/contAbout.vue";
import aboutab from "@/components/about/contAb.vue";
import Nav from "@/components/nav.vue";
import hezuo from "@/components/hezuo.vue";
import biao from "@/components/about/biao.vue";
import foot from "@/components/publick/foot.vue";
import left from "@/components/publick/leftT.vue";

export default {
  data() {
    return {
      list:[],
      imgList: [
        { imgurl: require("@/assets/img/4845.png"), id: 0 },
        { imgurl: require("@/assets/img/4845.png"), id: 1 },
        { imgurl: require("@/assets/img/4845.png"), id: 2 }
      ],
      navm: [
        { title: this.$i18n.t("nav.home"), url: "/" },
        { title: this.$i18n.t("nav.About"), url: "/about" }
      ],
      sum: "1",
      kk: "资质证书",
      tt: "",
      hh: "诚聘经营",
      jj: ""
    };
  },
  components: {
    blaners,
    navM,
    aboutus,
    aboutab,
    Nav,
    hezuo,
    biao,
    foot,
    left
  },
  mounted() {
    this.getimgList();
    this.  getlist();
    this.setTitle(this.$i18n.t('nav.About'));
  },
  methods: {
    getimgList() {
      this.axios
        .post("api/ad/index?", this.qs.stringify({ meun: 9,status_en: this.$root.statusEn }))
        .then(res => {
          this.imgList = res.data.data;
        });
    },

    //资格证书
      getlist() {
      this.axios
        .post("api/ad/img", this.qs.stringify({ id: 49 }))
        .then(res => {
          console.log(res.data.data,'0000022585858')
          this.list=res.data.data;
        });
    }
  }
};
</script>

<style  scoped>
.ko {
  height: 410px;
  display: flex;
  align-items: center;
  background: rgba(245, 245, 245, 1);
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 600px){
  .ko{
    display: none;
  }
}
</style>