<template>
    <div class="container">
        <div class="row md">
            <div class="col-md-6 imgbox">
                <img
                        src="../../assets/img/z5.png"
                        class="img-fluid d-none d-md-block d-sm-none d-lg-block d-xl-block"
                        alt="Responsive image"
                />
            </div>
            <div class="col-md-1">
                <div class="line"></div>
                <div class="year">
          <span
                  v-for="(v, i) in yearData"
                  :key="i"
                  :class="i == num ? 'lo' : ''"
                  @click="fun(i,v.id)"
          >{{ v.title}}</span>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <h5 class="card-title text-right h3font">{{$t('about.correction')}}</h5>
                        <p class="card-text spn" v-html="detaillist.content"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 imgbox">
                <img
                        src="../../assets/img/z5.png"
                        class="img-fluid d-block d-md-none d-sm-block d-lg-none d-xl-none"
                        alt="Responsive image"
                />
            </div>
        </div>
    </div>
</template>
<script>
    // d-none d-md-block d-sm-none d-lg-block d-xl-block
    export default {
        data() {
            return {
                yearData: [],
                detaillist: [],
                num: 0,
                filter: {
                    id: ""
                },
                lo: 1
            };
        },
        mounted() {
            this.getyearData();
            this.getdetaillist();
        },
        methods: {
            fun(i, id) {
                this.filter.id = id;
                this.num = i;
                this.getdetaillist();
            },
            getyearData() {
                this.axios
                    .post("api/ad/art", {meun: 30, status_en: this.$root.statusEn})
                    .then(res => {
                        this.yearData = res.data.data[0];
                        let firstData = this.yearData.slice(0, 1);
                        if (firstData.length) {
                            this.filter.id = firstData.shift().id;
                            this.getdetaillist();
                        }
                    });
            },
            getdetaillist() {
                var params = {
                    id: this.filter.id,
                    // num:this.data.num,
                    status_en: this.$root.statusEn
                };
                this.axios.post("api/ad/view", params).then(res => {
                    this.detaillist = res.data.data;
                });
            }
        }
    };
</script>

<style scoped>
    .spn /deep/ span {
        /* color: red !important; */
        font-size: 14px !important;
        font-family: Microsoft YaHei !important;
        font-weight: 400 !important;
        color: rgba(153, 153, 153, 1) !important;
    }

    .imgbox {
        position: relative;
        overflow: hidden;
    }

    .imgbox::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        display: block;
        content: "";
        width: 0;
        height: 0;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    .imgbox:hover::before {
        -webkit-animation: circle 0.75s;
        animation: circle 0.75s;
    }

    @-webkit-keyframes circle {
        0% {
            opacity: 1;
        }
        40% {
            opacity: 1;
        }
        100% {
            width: 200%;
            height: 200%;
            border-radius: 200%;
            opacity: 0;
        }
    }

    @keyframes circle {
        0% {
            width: 0;
            height: 0;
            opacity: 1;
        }
        40% {
            opacity: 1;
        }
        100% {
            width: 200%;
            height: 200%;
            opacity: 0;
        }
    }

    .md {
        margin-top: 60px;
        /* background-color: red; */
        min-height: 400px;
    }

    .line {
        width: 2px;
        height: 262px;
        border: 2px solid rgba(246, 146, 30, 1);
        margin-top: 50px;
    }

    .year {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 8px;
        /* justify-content: center; */
    }

    .year span {
        cursor: pointer;
    }

    .year .lo {
        width: 80px;
        height: 30px;
        display: block;
        background: rgba(246, 146, 30, 1);
        border-radius: 20px;
        text-align: center;
        line-height: 30px;
        font-size: 18px;
        color: white;
        margin-bottom: 10px;
    }

    .col-md-1 {
        /* margin-top: 50px;   */
        display: flex;
        justify-content: space-between;
    }

    .card-body h5 {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .card-body p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
    }

    .card {
        border: none;
        overflow-y: auto;
        height: 100%;
        /* background-color: green; */
    }

    @media screen and (max-width: 600px) {
        .card-body .h3font {
            font-size: 22px !important;
            font-weight: 900 !important;
            color: black !important;
        }

        .line {
            display: none;
        }

        .md {
            margin-top: 0;
        }

        .year {
            width: 100%;
            margin-top: 23px;
            flex-direction: row;
            justify-content: space-between;
        }
    }
</style>
