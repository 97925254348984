<template>
  <div class="container">
    <div class="row ga title">
      <div class="col-md-2 col-2">{{$t('about.job')}}</div>
      <div class="col-md-2 col-2">{{$t('home.Address')}}</div>
      <div class="col-md-2 col-2">{{$t('about.experience')}}</div>
      <div class="col-md-2 col-2">{{$t('about.salary')}}</div>
      <div class="col-md-2 col-2">{{$t('about.operation')}}</div>
    </div>
    <div class="row ga abc op" v-for="(item,index) of list" :key="index">
      <!-- <div class="col-md-2 col-2">{{$t('about.Marketing')}}</div> -->
      <div class="col-md-2 col-2">{{item.title}}</div>
      <!-- <div class="col-md-2 col-2">{{$t('about.Chengdu')}}</div> -->
      <div class="col-md-2 col-2">{{item.from}}</div>
      <!-- <div class="col-md-2 col-2">{{$t('about.years')}}</div> -->
      <div class="col-md-2 col-2">{{item.tags}}</div>
      <div class="col-md-2 col-2">{{item.author}}</div>
      <div class="col-md-2 col-2">
      

        <el-popover
      
          placement="left"
          title="●工作职责"
          width="500"
          trigger="click"
          
        >
        <div v-html="item.content"></div>
          <el-button slot="reference">{{$t('about.look')}}</el-button>
        </el-popover>

        <!-- <el-button v-popover:popover4>{{$t('about.look')}}</el-button> -->

        <!-- <div class="cover" :class="item.id==1?'':''" ></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      visible: false
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      this.axios
        .post("api/ad/art", { meun: 39, status_en: this.$root.statusEn})
        .then(res => {
          this.list = res.data.data[0];
        });
    }
  }
};
</script>
<style >
  /* @media screen and (max-width: 500px) {
/deep/ .el-popover{
        width:286px!important;
  }
  /deep/ .el-popover.el-popper.el-popover--plain{
    width: 286px!important;
  }
} */
@media screen and (max-width: 500px) {
  .row {
    font-size: 12px;
  }
    .el-popover--plain{
  width:285px !important;
  }
}
</style>
<style  scoped>
.el-popover{
color:chartreuse !important; 
background-color: green !important;
}

.container {
  margin-bottom: 100px;
}
.op {
  position: relative;
}
.cover {
  z-index: 9999;
  width: 100px;
  height: 100px;
  background-color: red;
  position: absolute;
  right: 108px;
  top: 28px;
}
.popper__arrow {
  width: 500px;
  height: 120px;
  background-color: red;
}
.col-2 {
  text-align: center;
}
.col-md-2 {
  /* text-align: center; */
  min-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 58px; */
}
.title {
  background: rgba(246, 146, 30, 0.3);
}
.ga {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  /* justify-content: center; */
}
.abc:nth-child(odd) {
  background-color: #fef4e8;
}
.di {
  background-color: #fcdebb;
}
.row > div:nth-child(5) {
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .row {
    font-size: 12px;
  }
  /deep/.el-popover--plain{
  width:285px !important;
}



}
</style>