<template>
  <div class="container-fluid kol">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-6" v-for="(v,i) in navList" :key="i" @click="get(v.id)">
          <span>{{v.ex_title}}</span>
          <span>{{v.title}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
    navList:[],
  }
},
methods:{
  // 获取导航栏数据
getNav() {
      this.axios
        .post("/api/ad/menu",this.qs.stringify({ meun: 4  , status_en: this.$root.statusEn}))
        .then(res => {
          console.log("产品中心导航栏的数据",res )
          this.navList=res.data.data.data
          console.log(this.navList)
        });
    },

// 点击获取新闻详情,测试用，可删除
get(e){
  console.log(e)
    // this.axios
    //     .post("/api/ad/pt",this.qs.stringify({ id: 6 , status_en: this.$root.statusEn}))
    //     .then(res => {
    //       console.log( "单个商品的数据",res.data.data,)
    //       // this.navList=res.data.data
    //     });
        this.$router.push({ path: "/softInfor", query: { id: e } });
}



},
  mounted(){
this.getNav()
  }

};
</script>


<style  scoped>
.kol{
  margin-bottom: 20px;
}
.container-fluid {
  height: 202px;

}
.row{
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-around;
}
.col-6{
  cursor: pointer;
}
.col-6 :hover{
  color: #65A8FF
}
.container-fluid {
  height: 302px;
  background-color: #e7d0c6;
  display: flex;
  align-items: center;
}
.col-md-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  /* font-size: 12px; */
}
@media screen and (max-width:600px) {
  .col-md-3 span {
    font-size: 12px;
    /* color: red; */
  }
  .col-md-3{
      margin: 10px 0 10px 0;  }
}
.container-fluid {
  height: 140px;

}
</style>