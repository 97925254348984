<template>
  <div class="container-fluid">
    <div class="container">
      <nav aria-label="breadcrumb  navm">
        <ol class="breadcrumb navm">
          <li class="breadcrumb-item" v-for="(v,i) in navM" :key="i">
            <router-link style="cursor: pointer;" tag="span" v-if="v.url" :to="v.url">{{v.title}}</router-link>
            <span href="javascript:;" v-else>{{v.title}}</span>
          </li>
          <!-- <li class="breadcrumb-item">
            <router-link to="/customerCa">客户案例</router-link>
          </li> -->
        </ol>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    // $breadcrumb-divider:quote(">");
  },
  props: ["navM"]
};
</script>

<style  scoped>

.breadcrumb-item :hover{
color: #65A8FF
}
.breadcrumb-item a{
  text-decoration: none;
}
/* .huk{
  margin: 0;
} */
nav{
  padding: 0;
  margin: 0;
}
.container-fluid {
  background-color: #f5f5f5;
}
.navm {
  background-color: #f5f5f5;
  padding: 0.75rem 0;
}
.navm > li > a {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
@media screen and (max-width: 600px){
      .breadcrumb{
        padding: 0;
      }
      .navm > li > a{
        font-size: 13px;
        
      }
}

</style>