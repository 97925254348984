<template>
    <div class="resolve">
        <blaners :imgList="imgList"></blaners>
        <nav-m :navM="navm"></nav-m>
        <contentr :navList="navlist" :tcImgList="tcImgList" :showLi='showLi'></contentr>
        <foot></foot>
    </div>
</template>
<script>
    import blaners from "@/components/publick/blanerS.vue";
    import navM from "@/components/solutinMethod/navM.vue";
    import contentr from "@/components/resolve/content.vue";
    import foot from "@/components/publick/foot.vue";

    export default {
        data() {
            return {
                navm: [
                    {title: this.$i18n.t('nav.home'), url: "/"},
                    {title: this.$i18n.t('nav.softwareProduct'), url: "/softp"},
                ],
                navlist: [
                    {title: this.$i18n.t('soft.SoftwareOverview'), url: "#i1"},
                    {title: this.$i18n.t('soft.SoftwareContent'), url: "#i2"},
                    {title: this.$i18n.t('soft.SoftwareCharacteristics'), url: "#i3"},
                    {title: this.$i18n.t('soft.UserCharacteristics'), url: "#i4"}
                ],
                imgList: [
                    {imgurl: require("@/assets/img/bl.png"), id: 0},
                    {imgurl: require("@/assets/img/bl.png"), id: 1},
                    {imgurl: require("@/assets/img/bl.png"), id: 2}
                ],
                textId: '',
                tcImgList: [],
                showLi: false,
            };
        },
        components: {
            blaners,
            navM,
            contentr,
            foot
        },
        mounted() {
            this.getimgList();

            this.textId = this.$route.query.id;
            this.getInforData();


        },
        methods: {
            getimgList() {
                this.axios
                    .post("api/ad/index", this.qs.stringify({meun: 4}))
                    .then(res => {
                        this.imgList = res.data.data;
                    });
            },

            getInforData() {
                var params = {
                    // limit: 3
                    id: this.textId,
                    status_en: this.$root.statusEn
                };
                this.axios
                    .post("api/ad/pt", this.qs.stringify(params))
                    .then(res => {
                        console.log(res.data.data, 'asdfasdfasdfasdf');
                        this.TextList = res.data.data;
                        this.tcImgList = this.TextList.dtp
                        //面包屑导航设置
                        console.log(this.$i18n.locale,'adsfasdfasdf');
                        this.navm = this.navm.slice(0, 2);
                        this.navm.push({
                            title: this.$i18n.locale == 'zh_CN' ? this.TextList.title : this.TextList.title_en,
                            url: false
                        });
                        this.setTitle(this.$i18n.locale == 'zh_CN' ? this.TextList.title : this.TextList.title_en);
                    });
            }
        }
    }

</script>

<style scoped>
</style>