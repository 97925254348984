<template>
    <div class="container bb">
        <resolve-F></resolve-F>
        <resolve-F></resolve-F>
        <resolve-F></resolve-F>
        <resolve-F></resolve-F>
        <resolve-F></resolve-F>
        <resolve-F></resolve-F>
        
    </div>
</template>

<script>
import resolveF from "@/components/solution.vue"

export default {
    components:{
        resolveF,
    }
}
</script>

<style  scoped>
.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
     /* justify-content: center; */
}
@media screen and (max-width:500px){
  .bb{
    /* display: flex;
    flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
}

}
</style>