<template>
  <div class="container-fluid gay">
    <div class="container">
      <div class="row gay">
        <div class="col-md-6">
          <div class="card" style="width:100%;">
            <div class="card-body">
              <h5 class="card-title h3font">{{$t('about.profile')}}</h5>
              <!--<p>Company profile</p>-->
              <p class="card-text" v-html="detaillist.content"></p>
<!--              <p class="card-text" >{{$t('about.detail')}}</p>-->
            </div>
          </div>
        </div>
        <div class="col-md-5 imgbox">
          <img
            src="../../assets/img/ab.png"
            class="img-fluid "
            alt
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// d-none d-md-block d-sm-none d-lg-block d-xl-block
export default {
  data() {
    return {
      detaillist: [],
    };
  },
  mounted() {
    var params = {
      id:166,
      // num:this.data.num,
      status_en: this.$root.statusEn
    };
    this.axios.post("api/ad/view", params).then(res => {
      this.detaillist = res.data.data;
    });
    // this.getyearData();
    // this.getdetaillist();
  },
  // methods: {
  //   fun(i, id) {
  //     console.log(id, "oooooooooooojjjjjjjjj");
  //     this.filter.id = id;
  //     this.num = i;
  //     this.getdetaillist();
  //   },
  //   getyearData() {
  //     this.axios
  //             .post("api/ad/art", { meun: 30, status_en: this.$root.statusEn })
  //             .then(res => {
  //               this.yearData = res.data.data[0];
  //             });
  //   },
  //   getdetaillist() {
  //     var params = {
  //       id: this.filter.id,
  //       // num:this.data.num,
  //       status_en: this.$root.statusEn
  //     };
  //     this.axios.post("api/ad/view", params).then(res => {
  //       this.detaillist = res.data.data;
  //     });
  //   }
  // }
};
</script>

<style scoped>
.imgbox {
  position: relative;
  overflow: hidden;
}

.imgbox::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.imgbox:hover::before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}
@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    border-radius: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.card {
  border: none;
  background-color: #f4f4f4;
}
.card-body > p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.gay {
  background-color: #f4f4f4;
  min-height: 360px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
 .card-body .h3font{
       font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
 }
@media screen and (max-width: 600px){
   .card-body .h3font {
    font-size: 22px !important;
    font-weight: 900 !important;
    color: black!important;
  }
}
</style>
