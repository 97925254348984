<template>
  <div class="contact">
    <blaners :imgList="imgList"></blaners>
    <nav-m :navM="navm"></nav-m>
    <div class="container">
      <div class="row lo">
        <div class="col-md-3">
          <div class="ksb-1">
            <div class="ksb_top">
              <span class="h3font">{{$t('home.Address')}}</span>
              <img src="../assets/img/DZ.png" class="img-fluid" alt="Responsive image" />
            </div>
            <P>{{mapAddress}}</P>
            <div class="ksb_top">
              <span class="h3font">{{$t('contact.phone')}}</span>
              <img src="../assets/img/DH.png" class="img-fluid" alt="Responsive image" />
            </div>
            <P>{{phoneNum}}</P>
            <div class="ksb_top">
              <span class="h3font">{{$t('contact.email')}}</span>
              <img src="../assets/img/YX.png" class="img-fluid" alt="Responsive image" />
            </div>
            <P>{{email}}</P>
          </div>
        </div>
        <div class="col-md-8">
          <div id="map" ref="map"></div>
        </div>
      </div>
    </div>
    <foot></foot>
    <left/>
  </div>
</template>

<script>
import blaners from "@/components/publick/blanerS.vue";
import navM from "@/components/solutinMethod/navM.vue";
import foot from "@/components/publick/foot.vue";
import BMap from "BMap";
import left from "@/components/publick/leftT.vue";
export default {
  data() {
    return {
      imgList: [
        { imgurl: require("@/assets/img/vide.png"), id: 0 },
        { imgurl: require("@/assets/img/vide.png"), id: 1 },
        { imgurl: require("@/assets/img/vide.png"), id: 2 }
      ],
      navm: [
        { title: this.$i18n.t("nav.home"), url: "/" },
        { title: this.$i18n.t("nav.Contact"), url: "/contact" }
      ],
      address: "",
      phoneNum: "",
      qq: "",
      email: "",
      wx: "",
      mapAddress: "",
      jinweidu: ""
    };
  },
  components: {
    blaners,
    navM,
    foot,
    left
  },
  methods: {
    getmapList() {
      this.axios.post("/api/ad/setting").then(res => {
        this.inforList = res.data.data;
        this.phoneNum = res.data.data[6].value;
        this.qq = res.data.data[53].value;
        this.wx = res.data.data[54].value;
        this.email = res.data.data[7].value;
        // this.mapAddress=res.data.data[8].value

        this.address = res.data.data[8].value;
      });
    },
    getimgList() {
      this.axios
        .post(
          "api/ad/index?",
          this.qs.stringify({ meun: 6, status_en: this.$root.statusEn })
        )
        .then(res => {
          this.imgList = res.data.data;
        });
    },
    getAdress() {
      this.axios.post("api/ad/bdapi").then(res => {
        console.log(res.data.data, "ooookllllkkkkkkkk");
        this.mapAddress = res.data.data.name;
        this.jinweidu = res.data.data.xy;
        this.ShowMap(
           this.jinweidu,
           this.mapAddress,
          "20"
        );
      });
    },
    ShowMap(zuobiao, addrsee, zoom) {
      var arrzuobiao = zuobiao.split(",");
      var map = new BMap.Map("map");
      map.centerAndZoom(new BMap.Point(arrzuobiao[0], arrzuobiao[1]), zoom);
      map.addControl(new BMap.NavigationControl());
      map.enableScrollWheelZoom();
      var marker = new BMap.Marker(
        new BMap.Point(arrzuobiao[0], arrzuobiao[1])
      );
      map.addOverlay(marker);
      var infoWindow = new BMap.InfoWindow(addrsee);
      marker.addEventListener("click", function() {
        this.openInfoWindow(infoWindow);
      });
      marker.openInfoWindow(infoWindow);
    }
  },
  mounted() {
    this.getAdress();
    this.getmapList();
    this.getimgList();
    this.setTitle(this.$i18n.t('nav.Contact'));
  }
};
</script>

<style scoped>
.ksb-1 .ksb_top span {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.ksb-1 .ksb_top p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.ksb_top {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: center;
}
.ksb_top span {
  margin-right: 5px;
}
#map {
  height: 400px;
  width: 100%;
  background-color: orange;
}
.lo {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 600px){
  .ksb_top .h3font{
    font-size: 18px !important;
    font-weight: 800 !important;
    /* color: red !important; */
  }
}
</style>
