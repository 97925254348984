<template>
  <div class="container bocc">
    <nav class="nav ju">
      <!-- <div class="ko" v-for="(v, i) in navb" :key="i" @click="fun(i)">
        <router-link tag="div" :to="v.url" :class="i == num ? 'ba' : ''">{{ v.title }}</router-link>
      </div>-->
      <div
        v-for="(v, i) in navl"
        :key="i"
        :class="{'active':i==num}"
        @click="fun(i,v)"
        class="kol"
      >{{ v.title }}</div>
    </nav>

    <div class="allBox de">
      <div class="card BO" style="width: 18rem;" v-for="(v, i) of allList" :key="i"  @click="funTz(v.id)">
        <img
          class="card-img-top img-fulid "
          :src="'http://admin.croxborder.com/'+v.image"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title hh">{{ v.title }}</h5>
          <h5 class="card-title ">{{ v.ex_title }}</h5>
          <p class="card-text tt" >
            {{v.ex_title_en}}
          </p>
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation example">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="filter.page"
        :page-size="filter.limit"
        layout="total, prev, pager, next"
        :total="totalCount"
        style="text-align:center"
      ></el-pagination>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      filter: {
        limit: 9,
        page: 1,
        meun: -1,
        status_en: this.$root.statusEn
      },
      allList: [],
      cur_page: 1,
      pageNum: 9,
      navl: [],
      totalCount: 0,
      all:'',
    };
  },
  mounted() {
    this.getnav();
    this.getallList();

    this.all=this.$i18n.t('home.ALL')
    
    console.log(this.all,"kiojkjijijijijijiji")
  },
  props: [],
  watch: {
    filter: {
      handler() {
        this.getallList();
      },
      deep: true
    }
  },
  methods: {
    funTz(i){
      let _this=this
          console.log(i,'lolololololololololololo')
          _this.$router.push({path:'/inforer',query:{id:i,bool:true}})
    },
    fun(i, v) {
      this.num = i;
      this.cur_page = 1;
      this.filter.page = 1;
      this.filter.meun = v.id;
    },

    handleCurrentChange(val) {
      this.cur_page = val;
      this.filter.page = val;
    },
    getnav() {
      this.axios
        .post("api/ad/menuid", { id: 8, status_en: this.$root.statusEn })
        .then(res => {
          this.navl = res.data.data;
          console.log(this.navl,"opopopop")
          var item = { id: -1, title: this.all};
          this.navl.unshift(item);
        });
    },
    getallList() {
      this.axios.post("api/ad/art", this.filter ).then(res => {
        this.allList = res.data.data[0];
        console.log(res.data.data,'oooooooommmmmmm')
        this.totalCount = res.data.data.count;
      });
    }
  }
};
</script>

<style scoped>

/deep/ .el-pager li.number{
color: #65A8FF;
}

/deep/ .el-pager li.active{
color: black;
}
.card-body .hh{
  font-size: 16px !important;
  font-weight: 800!important;
  color: black!important;
}
.tt{
   display: -webkit-box;
  -webkit-box-orient: vertical;
 -webkit-line-clamp: 5;
 overflow: hidden;
  margin: 0;
   
}
.de .card-body :hover {
  color: #65A8FF;
   cursor: pointer;
}
.kol{
  cursor: pointer;
}

.ju {
  justify-content: space-between;
  /* margin-bottom: 80px; */
}
.ju a {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-decoration: none;
}
.ju .ko .ba {
  margin-bottom: 10px;
  border-bottom: 3px solid orange;
}
.active {
  margin-bottom: 10px;
  border-bottom: 3px solid orange;
  cursor: pointer;
}
.allBox {
  width: 100%;
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  /* align-items: center; */
}
.BO {
  margin: 10px 0 10px 0;
}
.BO img{
  width: 286px;
  height: 215px;
}

.BO {
  transition: all 0.3s;
}

.BO:hover {
  transition: all 0.3s;
  box-shadow: 0px 0px 1px 2px #eee;
}
.card-body > h5:nth-child(1) {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.card-body > h5:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.card-body > p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.list > li > a {
  border: none;
  border-radius: 50%;
  text-decoration: none;
}
.bocc {
  margin-bottom: 50px;
}
.navbar-toggle {
  width: 50px;
  height: 20px;
}

.BO {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.de img {
  transition: all 0.4s;
}
.de img:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 700px) {
  .allBox {
    justify-content: center;
  }
  * {
    margin: 0;
    outline: 0 none;
    padding: 3px;
  }
  .ju {
    font-size: 14px;
    font-weight: 900;
    display: -webkit-box;
    margin-bottom: 5px;
    width: 100%;
    height: 50px;
    justify-content: center;
    /* background-color: goldenrod; */
    flex-wrap: nowrap;
    /* justify-content: space-between; */
    overflow-x:hidden;
    overflow-x: auto !important;
  }
  .ju div{
  margin-left: 18px;
  }
  .BO {
    margin: 15px 0 15px 0;
  }
  .ju .ko {
    margin: 0 2px 0 2px;
    /* background-color: firebrick; */
  }
  .ju .ko a {
    font-size: 14px;
  }
  .ju .ko .ba {
    margin: 10px;
  }
  .list li {
    margin: 0 5px 0 5px;
  }
}
</style>
