<template>
  <div class="containerBox">
    <div class="top">
      <nav class="nav ju h3font">
        <div
          v-for="(v, i) in navb"
          :key="i"
          :class="{'active':i==num} "
          @click="fun(i,v)"
        >{{ v.title }}</div>
      </nav>
    </div>
    <div class="container" v-for="(v,i) of fmslist" :key="i" @click="jump(v.id)">
      <div class="card mb-3 ju" style="width:100%;">
        <div class="row no-gutters kuu">
          <div class="col-md-4 d-none d-md-none d-lg-block de">
            <img :src="'http://admin.croxborder.com/'+v.image" class="card-img img-fulid" alt="..." />
          </div>
          <div class="col-md-8">
            <div class="card-body juu">
              <h5 class="card-title">{{v.title}}</h5>
              <p class="card-text">
                <small class="text-muted">{{v.date}}</small>
              </p>
              <p class="card-text ted" >{{v.ex_title}}</p>
              <!-- <a href="#" class="btn btn-warning glad">查看详情</a> -->
              <!-- <router-link to="/inforer" class="btn btn-warning glad">查看详情</router-link> -->
              <div class="btn btn-warning glad" >{{$t('home.LookDetails')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav aria-label="Page navigation example">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="filter.page"
        :page-size="filter.limit"
        layout="total, prev, pager, next"
        :total="totalCount"
        style="text-align:center"
      ></el-pagination>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      fmslist: [],
      filter: {
        limit: 4,
        page: 1,
        meun: -2,
         status_en: this.$root.statusEn
      },
      cur_page: 1,
      pageNum: 4,
      navb: [],
      totalCount: 0,
      all:''
    };
  },
  mounted() {
    this.getnavb();
    this.getfmslist();
    this.all=this.$i18n.t('home.ALL')
  },
  watch: {
    filter: {
      handler() {
        this.getfmslist();
      },
      deep: true
    }
  },
  methods: {
    fun(i, v) {
      this.num = i;
      this.cur_page = 1;
      this.filter.page = 1;
      this.filter.meun = v.id;
    },
    jump(id) {
      this.$router.push({
        name: "inforer",
        query: { id }
      });
    },
    getnavb() {
      this.axios.post("api/ad/menuid", { id: 26 , status_en: this.$root.statusEn}).then(res => {
        this.navb = res.data.data;
        console.log(this.navb[0],'.......<<<<>>>>>')
        var item = { id: -2, title: this.all };
        this.navb.unshift(item);
      });
    },
    getfmslist() {
      this.axios.post("api/ad/art", this.filter).then(res => {
        console.log(res.data.data,'ooo................iiiii')
        this.fmslist = res.data.data[0];
        this.totalCount = res.data.data.count;
      });
    },
    handleCurrentChange(val) {
      this.cur_page = val;
      this.filter.page = val;
    },
    jumpDetail() {
      this.$router.push({ path: "/inforer" });
    }
  }
};
</script>

<style scoped>
/deep/ .el-pager li.number{
color: #65A8FF;
}

/deep/ .el-pager li.active{
color: black;
}
.container{
  height: 200px;
  margin-bottom: 30px;
  /* background: red; */
  overflow: hidden;
}
.ju{
  cursor: pointer;
}
.juu{
  height: 210px;
  padding: 0 20px 0 0;
  /* background: red; */
}
.juu :hover{
  color: #65A8FF
}

.top {
  width: 100%;
  min-height: 100px;
}
.ju {
  display: flex;
  justify-content: space-around;
  font-size: 22px;
  max-width: 1555px;
  margin: 0 auto;
}
.ju a {
  text-decoration: none;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.ba {
  /* border-bottom: 3px solid orange; */
}
.active {
  margin-bottom: 10px;
  border-bottom: 3px solid orange;
  cursor: pointer;
}
.glad {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
  background-color: #f6921e;
  /* margin-top: 50px; */
  position: absolute;
  bottom: 22px;
}
.ted {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;  
    /* height: 4.9rem; */
    height: 60px;
    /* background: orange; */
    /* overflow: hidden; */

}
.card {
  border: none;
}
.card .card-body {
  padding-left: 20px;
}
.list > li > a {
  border: none;
  border-radius: 50%;
  text-decoration: none;
}
.de {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.de img {
  transition: all 0.4s;
  width: 100%;
  height: 100%;
  max-height: 200px;
}
.de img:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 600px){
  .h3font{
    font-size: 14px;
  }
  .nav{
    padding: 0;
  }
  .top{
    min-height: 0px;
  }
  .glad{
    margin: 0;
    margin-bottom: 0;
  }
}
</style>
